@import '../../../../../styles/colors';

$desktop-breakpoint: 992px;
$desktop-search-breakpoint: 608px;

$small-mobile-breakpoint: 361px;

.booking-box {
    width: 100%;
    border-radius: 8px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
    background-color: $white;
    padding: 0.5rem 0.5rem 1rem 0.5rem;
    margin-top: 1rem;
    p {
        margin: 0;
    }
    .title {
        display: flex;
        justify-content: space-between;
        font-weight: bold;
        font-size: 16px;
        border-bottom: 0.5px solid $border-grey;
        > p:first-child {
            margin-bottom: 0.5rem;
        }
    }
    .detail {
        padding-top: 1rem;
        display: flex;
        flex-direction: row;
        .car-img {
            width: 140px;
            height: 150px;
            object-fit: cover;
            margin-right: 0.5rem;
            border-radius: 4px;
        }
        .detail__item--car {
            display: flex;
            flex-direction: column;
            > div:not(:last-child) {
                padding-bottom: 0.5rem;
            }
            .car-title {
                font-weight: bold;
                font-size: 14px;
            }
            .time-box {
                display: flex;
                justify-content: space-between;
            }
        }
    }
}

@media screen and (min-width: $desktop-search-breakpoint) {
    .booking-box {
        width: 50%;
    }
}

@media screen and (min-width: $desktop-search-breakpoint) and (max-width: 1200px) {
    .booking-box {
        width: 60%;
    }
}
