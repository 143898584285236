@import '../../../../../styles/colors';

$desktop-breakpoint: 992px;
$desktop-search-breakpoint: 608px;

$small-mobile-breakpoint: 361px;

.header-box {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
    padding: 8px 16px !important;
    p {
        margin: 0;
    }
    .header-title {
        font-size: 16px;
        line-height: 1.2rem;
        .sub-title {
            font-size: 12px;
            color: rgba(0, 0, 0, 0.4);
        }
    }
    .header-avatar {
        position: relative;
        > img {
            width: 32px;
            height: 32px;
        }
    }
    .status {
        position: absolute;
        left: 4.5rem;
        bottom: 0.75rem;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        &.active {
            display: block;
            background-color: $green-status;
        }
        &.offline {
            display: none;
            background-color: transparent;
        }
    }
    .ant-page-header-back {
        display: block;
    }
    .back-btn:hover {
        color: $blue;
    }
}
@media screen and (min-width: $desktop-search-breakpoint) {
    .header-box {
        .status {
            left: 2.5rem;
        }
        .ant-page-header-back {
            display: none;
        }
    }
}
