@import '../../../styles/colors';

$desktop-breakpoint: 992px;
$desktop-search-breakpoint: 608px;

$small-mobile-breakpoint: 361px;

.loading {
    > div {
        min-height: 100vh;
    }
    .loading-img {
        img {
            width: 100%;
            height: 100px;
            margin: 0rem auto;
        }
    }
}
