@import '../../../../../styles/colors';

$desktop-breakpoint: 992px;
$desktop-search-breakpoint: 608px;

$small-mobile-breakpoint: 361px;

.chat {
    overflow: hidden;
    height: 100vh;
    background-color: $white;

    .chat-header {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 100;
        background-color: $white;
    }
    .chat-messages {
        width: 100%;
        height: 100vh;
        padding-bottom: 48px;
        z-index: 50;
        background-color: $grey-bg;
        &.online {
            padding-top: 56px;
        }
        &.offline {
            padding-top: 52px;
        }
        &--mobile,
        &--mobile.online,
        &--mobile.offline {
            padding: 0px;
        }
        &--dealer {
            background-color: $white;
        }
        &--admin {
            padding-top: 56px !important;
            padding-bottom: 0px;
        }
        &--input-disable {
            padding-bottom: 0px;
        }
    }
    .chat-footer {
        position: fixed;
        bottom: 0;
        width: 100%;
        z-index: 100;
        background-color: $white;
    }
}
.chat-alert-box {
    .ant-modal-content {
        border-radius: 8px;
        > button {
            > span {
                width: 46px;
                height: 46px;
                line-height: 46px;
            }
            .chat-alert-close {
                width: 18px;
                height: 18px;
            }
        }
        .ant-modal-body {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 1.5rem 1rem;
            p:first-child {
                font-weight: bold;
                color: $black;
                margin-bottom: 0.5rem;
                font-size: 16px;
            }
            p {
                color: $brownish-grey;
                margin: 0;
                font-size: 14px;
            }
        }
    }
}

// @media screen and (min-width: $desktop-search-breakpoint) {}
