@font-face {
    font-family: 'drivehub-icon';
    src: url(../assets/fonts/drivehub-icon.eot?87646312);
    src: url(../assets/fonts/drivehub-icon.eot?87646312#iefix) format('embedded-opentype'),
        url(../assets/fonts/drivehub-icon.woff2?87646312) format('woff2'),
        url(../assets/fonts/drivehub-icon.woff?87646312) format('woff'),
        url(../assets/fonts/drivehub-icon.ttf?87646312) format('truetype'),
        url(../assets/fonts/drivehub-icon.svg?87646312#drivehub-icon) format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap; /* Define how the browser behaves during download */
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'drivehub';
    src: url('../font/drivehub.svg?56003403#drivehub') format('svg');
  }
}
*/

[class^='icon-']:before,
[class*=' icon-']:before {
    font-family: 'drivehub-icon', sans-serif;
    font-style: normal;
    font-weight: normal;
    speak: none;

    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: 0.2em;
    text-align: center;
    /* opacity: .8; */

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    line-height: 1;

    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: 0.2em;

    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-cancel-circle:before {
    content: '\e805';
} /* '' */
.icon-close:before {
    content: '\e806';
} /* '' */
.icon-phone:before {
    content: '\e807';
} /* '' */
.icon-calendar:before {
    content: '\e808';
} /* '' */
.icon-search:before {
    content: '\e809';
} /* '' */
.icon-eye:before {
    content: '\e80a';
} /* '' */
.icon-eye-off:before {
    content: '\e80b';
} /* '' */
.icon-user:before {
    content: '\e80c';
} /* '' */
.icon-key:before {
    content: '\e80d';
} /* '' */
.icon-login:before {
    content: '\e80e';
} /* '' */
.icon-logout:before {
    content: '\e80f';
} /* '' */
.icon-line:before {
    content: '\e810';
} /* '' */
.icon-line-2:before {
    content: '\e81c';
} /* '' */
.icon-inter:before {
    content: '\e811';
} /* '' */
.icon-local:before {
    content: '\e812';
} /* '' */
.icon-cash:before {
    content: '\e813';
} /* '' */
.icon-card:before {
    content: '\e814';
} /* '' */
.icon-down:before {
    content: '\e815';
} /* '' */
.icon-left:before {
    content: '\e816';
} /* '' */
.icon-right:before {
    content: '\e817';
} /* '' */
.icon-up:before {
    content: '\e818';
} /* '' */
.icon-ok-circle:before {
    content: '\f06d';
} /* '' */
.icon-doc-text-inv:before {
    content: '\f15c';
} /* '' */
.icon-instagram:before {
    content: '\f16d';
} /* '' */
.icon-facebook-official:before {
    content: '\f230';
} /* '' */
.icon-twitter-squared:before {
    content: '\f304';
} /* '' */
.icon-link:before {
    content: '\f08e';
} /* '' */
.icon-auth:before {
    content: '\e801';
} /* '' */
.icon-facebook:before {
    content: '\f09a';
} /* '' */
.icon-google:before {
    content: '\f1a0';
} /* '' */
.icon-chain:before {
    content: '\e803';
} /* '' */
.icon-attention:before {
    content: '\e800';
} /* '' */
.icon-ok:before {
    content: '\e804';
} /* '' */
.icon-credit-card:before {
    content: '\e819';
} /* '' */
.icon-location:before {
    content: '\e81a';
} /* '' */
.icon-baht:before {
    content: '\e83b';
} /* '' */
.icon-file:before {
    content: '\e83c';
} /* '' */
.icon-car:before {
    content: '\e83e';
} /* '' */
.icon-money:before {
    content: '\e83f';
} /* '' */
.icon-thunder:before {
    content: '\e840';
} /* '' */
.icon-plus:before {
    content: '\e81b';
} /* '' */
.icon-minus:before {
    content: '\e81d';
} /* '' */
.icon-copy:before {
    content: '\e81e';
} /* '' */
.icon-help-circled:before {
    content: '\e704';
} /* '' */

// Override rc-dialog
.rc-dialog {
    @media (min-width: 768px) {
        width: 85vh !important;
    }
}
