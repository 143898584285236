@import '../../../../../styles/colors';

$desktop-breakpoint: 992px;
$desktop-search-breakpoint: 608px;

$small-mobile-breakpoint: 361px;

.message-box {
    height: 100%;
    padding: 0rem 1rem;
    overflow-y: scroll;
    overflow-x: hidden;

    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
    .message-group {
        &__item {
            z-index: 10;
        }
    }
    .loading-message {
        display: flex;
        justify-content: center;
    }
    &--dealer {
        .message-group {
            &__item {
                .message {
                    background-color: $grey-bg;
                }
            }
        }
    }
}

@media screen and (min-width: $desktop-search-breakpoint) {
    .message-box {
        background-color: $white;
    }
}
