@import '../../../styles/colors';

$desktop-breakpoint: 992px;
$desktop-search-breakpoint: 608px;

$small-mobile-breakpoint: 361px;

.box-404 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: $light-red;
    min-height: 100vh;
    color: $white;
    > div {
        padding-bottom: 12rem;
    }
    .error-code {
        text-align: center;
        font-size: 90px;
    }
    .error-text {
        text-align: center;
        font-size: 18px;
        a {
            color: $white;
        }
        .back-btn {
            background-color: #d9534f;
            border-color: #d43f3a;
            border-radius: 2px;
            &:hover {
                background-color: $red;
            }
        }
        .icon-back {
            padding-right: 0.5rem;
        }
    }
}
