@import 'styles/colors';

$desktop-breakpoint: 992px;
$desktop-search-breakpoint: 608px;

$small-mobile-breakpoint: 361px;

@import url('https://fonts.googleapis.com/css?family=Prompt:300,400,500,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Bai%20Jamjuree:300,400,500,700&display=swap');

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    // font-family: 'Prompt';
    // font-weight: 400;
    // line-height: 20px;
    // font-size: 1rem /* 16px */;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    // font-family: 'Prompt';
}
