@import '../../../styles/colors';

$desktop-breakpoint: 992px;
$desktop-search-breakpoint: 608px;

$small-mobile-breakpoint: 361px;

.preview--detail {
    color: #6d7278;
    line-height: 1.5;
}

.message-preview--wrapper {
    padding: 1rem;
    .message {
        max-width: 100% !important;
    }
}
