@import '../../../styles/colors';

$desktop-breakpoint: 992px;
$desktop-search-breakpoint: 608px;

$small-mobile-breakpoint: 361px;

.text-box {
    line-height: 1rem;
    &__label {
        font-size: 12px;
        color: $brownish-grey;
    }
    &__text {
        font-size: 14px;
        color: $black;
    }
}
