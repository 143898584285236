@import '../../../../../styles/colors';

$desktop-breakpoint: 992px;
$desktop-search-breakpoint: 608px;

$small-mobile-breakpoint: 361px;

.message-box {
    .message-group {
        padding-bottom: env(safe-area-inset-bottom);
        &__item {
            display: flex;
            margin: 0.5rem 0rem;
            align-items: center;
            .avatar {
                margin-right: 0.5rem;
                margin-bottom: auto;
            }
            .message {
                background-color: $white;
                border-radius: 10px;
                box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
                padding: 0.5rem;
                max-width: 70%;
                word-break: break-all;
                white-space: pre-wrap;
                
                &--text {
                    font-size: 14px;
                }
                
                &--html {
                    white-space: initial;
                    ul {
                        padding-inline-start: 12px;
                        li:before {
                            display: inline-block;
                            content: '-';
                            width: 1em;
                            margin-left: -1em;
                        }
                        li {
                            list-style-type: none;
                        }
                    }
                }

                &--img {
                    padding: 0px;
                    .ant-image {
                        > img {
                            width: auto;
                            // min-width: 150px;
                            min-width: 80px;
                            min-height: 100px;
                            max-width: 100%;
                            max-height: 200px;
                            object-fit: contain;
                            border-radius: 10px;
                        }
                        .ant-image-placeholder {
                            display: flex;
                            .img-loading {
                                width: 50px;
                                margin: 15px;
                                margin-left: auto;
                                margin-right: auto;
                            }
                        }
                    }
                    .ant-image-error {
                        > img {
                            padding: 1rem;
                            margin: 2rem;
                            width: 100px;
                            height: 100px;
                            min-width: unset;
                            min-height: unset;
                        }
                    }
                }

                &--file {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    width: 50%;
                    padding: 1rem 0.5rem;
                    .file-icon {
                        color: $dark-blue;
                        padding-bottom: 0.5rem;
                    }
                }
                &--time {
                    margin-top: auto;
                    margin-left: 0.25rem;
                    font-size: 10px;
                    color: $brownish-grey;
                }
                &--copy {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    img {
                        width: 12px;
                    }
                    div {
                        margin-left: 4px;
                        font-size: 12px;
                        color: #0091ff;
                    }
                    padding-top: 6px;
                    padding-bottom: 6px;
                }
            }

            &--consumer {
                flex-direction: row-reverse;
                .message {
                    background-color: $message-blue;
                }
                .avatar {
                    margin-right: 0rem;
                    margin-left: 0.5rem;
                    margin-top: auto;
                    margin-bottom: unset;
                }
                .message--time {
                    margin-left: 0rem;
                    margin-right: 0.25rem;
                }
            }
            &--hide {
                display: none;
            }
            &--separate {
                justify-content: center;
                align-items: center;
                color: $soft-black;
                border-radius: 0.75rem;
                background-color: $soft-grey-1;
                font-size: 12px;
                padding: 0.25rem;
                .separate-text {
                    padding: 0px 0.5rem;
                }
            }

            &.group-date {
                justify-content: center;
                margin: 1rem 0rem;
            }
            &.group-remind {
                display: flex;
                flex-direction: column;
                .remind {
                    display: flex;
                    background-color: $light-blue;
                    font-size: 12px;
                    border-radius: 4px;
                    padding: 1rem;
                    color: $dark-blue;
                    line-height: 1.1rem;
                    align-items: center;
                    p {
                        margin: 0;
                    }
                    img {
                        margin-right: 0.5rem;
                        width: 24px;
                        height: 24px;
                    }
                }
            }
        }
    }
    // Switch side between Dealer and Consumer.
    &--dealer {
        .message-group {
            &__item {
                &--consumer {
                    flex-direction: row;
                    .avatar {
                        margin-left: 0rem;
                        margin-right: 0.5rem;
                        margin-top: unset;
                        margin-bottom: auto;
                    }
                    .message--time {
                        margin-left: 0.25rem;
                        margin-right: 0rem;
                    }
                }
                &--dealer {
                    flex-direction: row-reverse;
                    .message {
                        background-color: $message-blue !important;
                    }
                    .avatar {
                        margin-right: 0rem;
                        margin-left: 0.5rem;
                        margin-top: auto;
                        margin-bottom: unset;
                    }
                    .message--time {
                        margin-left: 0rem;
                        margin-right: 0.25rem;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: $desktop-search-breakpoint) {
    .message-box {
        .message-group {
            &.group-remind {
                .remind {
                    font-size: 14px;
                    line-height: 1.2rem;
                }
            }
            &__item {
                .message {
                    max-width: 40%;
                    &--img,
                    &--file {
                        cursor: pointer;
                    }
                    &--file {
                        width: 20%;
                    }
                }
            }
        }
    }
}
