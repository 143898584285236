@import '../../../../../styles/colors';

$desktop-breakpoint: 992px;
$desktop-search-breakpoint: 608px;

$small-mobile-breakpoint: 361px;

.footer-box {
    display: flex;
    align-items: center;
    padding: 0.5rem 0rem 0.5rem 1rem;
    margin-bottom: env(safe-area-inset-bottom);
    box-shadow: 0 -3px 6px 0 rgba(0, 0, 0, 0.08);
    &__item {
        flex-grow: 0;
        &:last-child {
            flex-grow: 1;
            display: flex;
            align-items: center;
            textarea {
                resize: none;
            }
        }
        &:last-child.resize {
            align-items: flex-end;
        }
    }
    &.resize {
        align-items: flex-end;
    }
    .message-input {
        border-radius: 8px;
        background-color: $grey-input;
        border-color: transparent;
        max-height: 200px !important;
        white-space: pre-wrap;
    }
    .camera-input {
        &[type='file'] {
            display: none;
        }
    }
    .btn {
        padding: 0;
        border: none;
        background-color: transparent;
        img {
            width: 24px;
            height: 24px;
        }
        &:focus {
            outline: none;
        }
    }
    .btn--album {
        width: fit-content;
        height: fit-content;
        border: none;
        line-height: 0 !important;
        padding: 0;
    }
    .btn--send {
        margin-left: 0.5rem;
        margin-right: 0;
        padding-right: 1rem;
    }
    .btn--camera {
        margin: 0rem 0.5rem;
    }
}
@media screen and (min-width: $desktop-search-breakpoint) {
    .footer-box {
        .message-input {
            max-height: 200px !important;
        }
        &__item {
            &--camera {
                display: none;
            }
            &--gallery {
                margin-right: 0.5rem;
            }
        }
    }
}
@media screen and (max-width: $small-mobile-breakpoint) {
    .footer-box {
        .message-input {
            max-height: 100px !important;
        }
    }
}
